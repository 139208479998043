.sarpras .image {
    position: relative;
  }
  
  .sarpras .content {
    position: absolute;
    top: 0;
    bottom: 0;
    /* left: 0; */
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    gap: 10px;
    color: #e8e8e8;
    padding: 20px;
    line-height: 1.5;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(30px);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .sarpras .content h4 {
    align-self: flex-end;
  }
  
  .sarpras .image:hover .content {
    opacity: 1;
    transform: translateY(0);
    background-color: rgba(6, 23, 69, 0.5);
  }
  
  .sarpras .image:hover .img-content {
    filter: blur(1px);
  }
  

  

  .page-number {
    display: inline-block;
    padding: 15px 20px;
    /* margin: 5px; */
    border: 1px solid #ccc;
    font-weight: bold;
    cursor: pointer;
  }
  
  .page-number:hover {
    background-color: #FFB606;
    border: 1px solid #FFB606;
    color: #fff;
  }

  .page-number.active {
    background-color: #FFB606;
    border: 1px solid #FFB606;
    color: #fff;
  }

  .sarpras .btn{
    background-color: #fff;
    font-weight: bold;
    color: #000000;
    display: inline-block;
    padding: 15px 20px;
    /* margin: 5px; */
    border: 1px solid #ccc;
    border-radius: 0;
  }
  
  /* Tambahkan gaya untuk overlay dan preview gambar */
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 999; */
  
}

.image-preview {
  max-width: 80%;
  max-height: 80%;
}

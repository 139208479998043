.Login .login {
    color: #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
    font-weight: bold;
    font-size: x-large;
  }
  
 .Login .container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Menggunakan tinggi 100% dari viewport */
  }
   
  .Login .card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    width: 450px;
    flex-direction: column;
    gap: 35px;
    background: rgba(6, 23, 69, 0.95);
    box-shadow: 16px 16px 32px #c8c8c8,
          -16px -16px 32px #fefefe;
    border-radius: 8px;
  }
  
  .Login .inputBox {
    position: relative;
    width: 250px;
  }
  
  .Login .inputBox input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1em;
    background: transparent;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: 0.1s;
    border-bottom-left-radius: 8px;
  }
  
  .Login .inputBox span {
    margin-top: 5px;
    position: absolute;
    left: 0;
    transform: translateY(-4px);
    margin-left: 10px;
    padding: 10px;
    pointer-events: none;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 3px;
    border-radius: 8px;
  }
  
  .Login .inputBox input:valid~span,
  .Login .inputBox input:focus~span {
    transform: translateX(113px) translateY(-15px);
    font-size: 0.8em;
    padding: 5px 10px;
    background: #000;
    letter-spacing: 0.2em;
    color: #fff;
    border: 2px;
  }
  
  .Login .inputBox input:valid,
  .Login .inputBox input:focus {
    border: 2px solid #fff;
    border-radius: 8px;
  }
  
  .Login .enter {
    height: 50px;
    width: 100px;
    border-radius: 5px;
    border: 2px solid #fff;
    cursor: pointer;
    background-color: transparent;
    transition: 0.5s;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 2px;
    margin-bottom: 1em;
    background-color: #fff;
  }
  
  .Login .enter:hover {
    background-color: rgb(0, 0, 0);
    color: white;
  }
  
  
body{
    font-family: 'Poppins', sans-serif;
    background-color: #e8e8e8;
  }
  
  a{
    text-decoration: none;
  }
  footer{
    background-color: #061745;
    padding: 50px;
    padding-bottom: 20px;
    /* margin-top: 50px; */
  }
  /* .container-fluid{
    --bs-gutter-x: 0 !important;
  } */
  .row{
    --bs-gutter-x: 0 !important;

  }

  .show-berita{
    color: #061745;
    background-color: #fff;
    border: 2px solid #061745; 
    padding: 13px 20px;
    font-weight:700;
  }
  .show-berita:hover{
    color: #fff;
    background-color: #061745;
  }

  .show-prestasi{
    color: #fff;
    background-color: #061745;
    border: 2px solid #fff;
    padding: 13px 20px;
    font-weight:700;
  }
  .show-prestasi:hover{
    color: #061745;
    background-color: #fff;
  }
  

  .show-galeri{
    color: #061745;
    background-color: #E7E7E7;
    border: 2px solid #061745; 
    padding: 5px 20px;
    font-weight:700;
  }
  .show-galeri:hover{
    color: #E7E7E7;
    background-color: #061745;
  }

  #socmed, .icon{
    font-size:34px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #002147;
    width: 60px;
    height: 60px;
    line-height: 57px;
    display: inline-block;
    text-align: center;
  }

  /* .container-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.card {
  width: 100%;
}

.home-jurusan .image {
  position: relative;
}

.home-jurusan .content{
  position: absolute;
  top: 0;
  /* left: 0; */
  width: 360px;
  height: 173px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(30px);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.home-jurusan .image:hover .content{
  opacity: 1;
  transform: translateY(0);
  background-color: rgba(6, 23, 69, 0.5);
}

.home-jurusan .image:hover .img-content{
  filter: blur(1px);
}

/* Carousel Galeri */

.tentang-sekolah table {
    /* font-family: arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    /* max-width: 100%; */
  }
  
  .tentang-sekolah td, th {
    /* border: 1px solid #dddddd; */
    text-align: left;
    padding: 10px;
  }
  
  .tentang-sekolah tr:nth-child(odd) {
    background-color: #ffedc1;
  }

  .tentang-sekolah tr:nth-child(even) {
    background-color: #fff;
  }

  /* Peserta didik */

  .peserta-didik table {
    /* font-family: arial, sans-serif; */
    border: 1px solid #000000;
    width: 100%;
    
    /* max-width: 100%; */
  }
  
  .peserta-didik td, th {
    /* border: 1px solid #dddddd; */
    text-align:center;
    padding: 10px;
    border: 3px solid #000000;
  }
  
  .peserta-didik tr:nth-child(odd) {
    background-color: #fff;
  }

  .peserta-didik tr:nth-child(even) {
    background-color: #fff;
  }

  /* pendidik tenaga kependidikan */
  .pendidik-tenagakependidikan table {
    /* font-family: arial, sans-serif; */
    border: 1px solid #000000;
    width: 100%;
    
    /* max-width: 100%; */
  }
  
  .pendidik-tenagakependidikan td, th {
    /* border: 1px solid #dddddd; */
    text-align:center;
    padding: 10px;
    border: 3px solid #000000;
  }
  
  .pendidik-tenagakependidikan tr:nth-child(odd) {
    background-color: #fff;
  }

  .pendidik-tenagakependidikan tr:nth-child(even) {
    background-color: #fff;
  }

  /* style buat bg yang di atas */

  .bg {
    position: relative;
  }
  
  .content {
    position: relative;
    top: -60px; /* Sesuaikan dengan jumlah jarak yang diinginkan */
    z-index: 1;
  }

  /* Visi Misi */
  .list-group-item {
    margin-bottom: 20px;
  }
  